/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import { Layout } from "antd";
import styles from "./auth.style";
import { Switch, Redirect, Route } from "react-router-dom";
import Nav from "../../components/nav/nav";
import Footer from "../../components/footer/footer.index";
import NotFoundPage from "../not-found/not-found";
import SigninPage from "../signin/signin";
import SignUpPage from "../signup/signup";
import ResetPasswordPage from "../reset-password/reset-password";
import {useHistory} from "react-router";
import { DISABLED_FEATURES } from "../../config/constants";

const AuthPage = () => {
  const { Header, Content } = Layout;
  const history = useHistory();
  useEffect(() => {
    const authToken = window.localStorage.getItem('auth_token');
    if (authToken) {
      history.push('/protected');
    }
  }, []);
  return (
    <Layout className="layout">
      <Header className="auth-nav" style={styles.header}>
        <Nav />
      </Header>
      <Content style={styles.authContainer} className="auth-container">
        <div style={styles.innerContainer}>
          <Switch>
            <Redirect exact path="/auth" to="/auth/signin" />
            <Route path="/auth/signin" component={SigninPage} />
            {DISABLED_FEATURES.CREATE_ACCOUNT && (
              <Route path="/auth/signup" component={SignUpPage} />
            )}
            <Route path="/auth/reset-password" component={ResetPasswordPage} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </div>
      </Content>
      <Footer />
    </Layout>
  );
};

export default AuthPage;
