type Config = {
  apiUrl: string;
};


const config: Config = {
  apiUrl: process.env.REACT_APP_VENDOR_GRAPHQL_ENDPOINT || 'https://eshop-master-athenea-mx.elenas.cloud/vendor_gql/',
};


export default config;

//https://elenas.netlify.com/pdfs/Acuerdo%20de%20Uso%20de%20Plataforma.pdf
//https://elenas.netlify.com/pdfs/Politica%20de%20Devoluciones.pdf
//https://elenas.netlify.com/pdfs/Politicas%20para%20Proveedores.pdf
