/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import {Link, useHistory} from "react-router-dom";
import { Layout, Button, Input, Typography, Row, Col, Icon } from "antd";
import Nav from "../../components/nav/nav";
import Footer from "../../components/footer/footer.index";
import styles from "./home.style";
import { useOvermind } from "../../overmind";

const HomePage = () => {
  const { Header, Content } = Layout;
  const { Title } = Typography;
  const history = useHistory();
  useEffect(() => {
    const authToken = window.localStorage.getItem('auth_token');
    if (authToken) {
      history.push('/protected');
    }
  }, []);
  // OVERMIND
  const {
    state: { configurations },
  } = useOvermind();
  const bannerPais = `Vende tus productos en toda ${configurations.COUNTRY_CODE === "MX" ? "México" : "Colombia"}.`;

  return (
    <div style={styles.welcomeContainer}>
      <Layout style={styles.layout}>
        <Header style={styles.header}>
          <Nav />
        </Header>

        <Content style={styles.container}>
          <div className="welcome-banner">
            <Row gutter={16}>
              <Col sm={24} md={11}>
                <div style={styles.bannerTextWrap}>
                  <Title className="banner-title" style={styles.bannerTitle}>
                    {bannerPais}
                  </Title>
                  <p style={styles.bannerText}>
                    Ofrece y vende tus productos por medio de una red de mujeres
                    poderosas con presencia en todo el pais.
                  </p>
                  <Button
                    className="banner-button"
                    style={styles.bannerButton}
                    type="primary"
                  >
                    <Link to="/auth/signin">¡Comenzar ahora!</Link>
                  </Button>
                </div>
              </Col>
              <Col sm={24} md={13}>
                <img
                  style={styles.bannerImage}
                  alt="banner"
                  src={require("../../assets/images/banner.svg")}
                />
              </Col>
            </Row>
          </div>
          <section
            className="value-props"
            style={{
              ...styles.section,
              ...styles.textCenter,
              paddingBottom: 38,
            }}
          >
            <Row gutter={50}>
              <Col sm={24} md={8}>
                <img
                  alt=""
                  style={styles.valuePropImg}
                  src={require("../../assets/images/vp-1.svg")}
                />
                <Title style={styles.valuePropTitle} level={3}>
                  Expande tu alcance sin dolores de cabeza
                </Title>
                <Button style={styles.valuePropButton} type="primary">
                  <Link to="/auth/signin">Ver más</Link>
                </Button>
              </Col>
              <Col sm={24} md={8}>
                <img
                  alt=""
                  style={styles.valuePropImg}
                  src={require("../../assets/images/vp-2.svg")}
                />
                <Title style={styles.valuePropTitle} level={3}>
                  Actúa a nivel nacional sin perder ese toque personal
                </Title>
                <Button style={styles.valuePropButton} type="primary">
                  <Link to="/auth/signin">Ver más</Link>
                </Button>
              </Col>
              <Col sm={24} md={8}>
                <img
                  alt=""
                  style={styles.valuePropImg}
                  src={require("../../assets/images/vp-3.svg")}
                />
                <Title style={styles.valuePropTitle} level={3}>
                  Aprende sin tomar grandes riesgos
                </Title>
                <Button style={styles.valuePropButton} type="primary">
                  <Link to="/auth/signin">Ver más</Link>
                </Button>
              </Col>
            </Row>
          </section>
          <section style={{ ...styles.section, ...styles.bgWhite }}>
            <Row gutter={16}>
              <Col sm={24} md={12}>
                <img
                  alt=""
                  style={styles.sectionImage}
                  src={require("../../assets/images/vp-1.svg")}
                />
              </Col>
              <Col sm={24} md={{ span: 9, offset: 1 }}>
                <div style={styles.sectionTextWrap}>
                  <Title style={styles.sectionTitle}>
                    Expande tu alcance sin dolores de cabeza
                  </Title>
                  <p style={styles.sectionText}>
                    Vende más por medio de una red de mujeres poderosas
                    distribuidas por todo el país. Llevamos tus productos desde
                    la Guajira hasta el Amazonas - ¡sin que aumente tu carga
                    operativa!
                  </p>
                  <Button style={styles.sectionButton} type="primary">
                    <Link to="/auth/signin">Conocer más</Link>
                  </Button>
                </div>
              </Col>
            </Row>
          </section>
          <section style={{ ...styles.section }}>
            <Row gutter={16}>
              <Col sm={24} md={{ span: 12, offset: 3, push: 9 }}>
                <img
                  alt=""
                  style={styles.sectionImage}
                  src={require("../../assets/images/vp-2.svg")}
                />
              </Col>
              <Col sm={24} md={{ span: 9, pull: 15 }}>
                <div style={styles.sectionTextWrap}>
                  <Title style={styles.sectionTitle}>
                    Actúa a nivel nacional sin perder ese toque personal
                  </Title>
                  <p style={styles.sectionText}>
                    Disfruta de la ventaja de escalar tus ventas a través del
                    canal más poderoso que existe: la confianza entre los seres
                    humanos.
                  </p>
                  <Button style={styles.sectionButton} type="primary">
                    <Link to="/auth/signin">Conocer más</Link>
                  </Button>
                </div>
              </Col>
            </Row>
          </section>
          <section style={{ ...styles.section, ...styles.bgWhite }}>
            <Row gutter={16}>
              <Col sm={24} md={12}>
                <img
                  alt=""
                  style={styles.sectionImage}
                  src={require("../../assets/images/vp-3.svg")}
                />
              </Col>
              <Col sm={24} md={{ span: 9, offset: 1 }}>
                <div style={styles.sectionTextWrap}>
                  <Title style={styles.sectionTitle}>
                    Aprende sin tomar grandes riesgos
                  </Title>
                  <p style={styles.sectionText}>
                    Experimenta, aprende y toma decisiones ágiles y bien
                    informadas - siempre manteniendo el control sobre tu
                    producto.
                  </p>
                  <Button style={styles.sectionButton} type="primary">
                    <Link to="/auth/signin">Conocer más</Link>
                  </Button>
                </div>
              </Col>
            </Row>
          </section>
          <section
            style={{
              ...styles.section,
              // ...styles.bgGradient,
              ...styles.textCenter,
            }}
          >
            <Row
              type="flex"
              justify="center"
              align="middle"
              style={{ flexDirection: "column", flexFlow: "row wrap" }}
            >
              <Col sm={24} md={8}>
                <div style={styles.sectionTextWrap}>
                  <Title
                    style={{ ...styles.sectionTitle, }}
                  >
                    ¿Quieres conocer más?
                  </Title>
                  <p
                    style={{
                      ...styles.sectionText,
                      fontSize: 18,
                    }}
                  >
                    Déjanos tu correo y estaremos en contacto contigo para
                    agendar una sesión informativa.
                  </p>
                  <Input
                    prefix={<Icon type="mail" style={{ color: "#A3A6CC" }} />}
                    style={styles.sectionInput}
                    placeholder="Tu correo electrónico"
                  />
                  <Button style={styles.sectionButton} type="primary">
                    <Link to="/auth/signin">¡Comenzar ahora!</Link>
                  </Button>
                </div>
              </Col>
            </Row>
          </section>
        </Content>
        <Footer />
      </Layout>
    </div>
  );
};

export default HomePage;
