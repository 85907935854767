import { colors } from "../../constants/colors";

export default {
  footer: {
    backgroundColor: colors.eva_5,
    textAlign: "center",
    paddingTop: 56,
    paddingBottom: 64,
  },
  textTitleHelp: {
    color: colors.eva_50,
    fontSize: "16px",
    fontWeight: 700,
    marginBottom: "5px",
  },
  textSubTitleHelp: {
    color: colors.eva_80,
    fontSize: "12px",
    fontWeight: 400,
    marginBottom: "25px",
    display: "block",
  },
  support: {
    height: "auto",
    width: "60px",
  },
  buttonOutlined: {
    margin: "5px",
    backgroundColor: "transparent",
    borderRadius: "24px",
    fontWeight: "700",
    fontSize: "12px",
  },
  buttonOutlinedBlue: {
    border: `1px solid ${colors.eva_50}`,
    color: colors.eva_50,
  },
};
