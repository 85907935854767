export const colors = {
  white: '#FFFFFF',
  stone_10: '#EBEDEE',
  stone_20: '#D8DBDC',
  stone_30: '#C4C9CB',
  stone_40: '#B1B7B9',
  stone_50: '#9DA5A8',
  stone_60: '#767C7E',
  stone_70: '#4F5354',
  stone_80: '#27292A',
  eva_5: '#dcedf5',
  eva_10: '#bbdceb',
  eva_20: '#9acbe1',
  eva_30: '#6cb3d3',
  eva_40: '#4fa5ca',
  eva_50: '#238EBD',
  eva_60: '#2081ac',
  eva_70: '#196586',
  eva_80: '#134e68',
  eva_90: '#0f3c4f',
  eva_50_30: '#238EBD30',
  eva_50_80: '#238EBD80',
  eva_50_90: '#238EBD90',
  pacific_10: '#D2E2FA',
  pacific_20: '#A4C5F6',
  pacific_30: '#77A9F1',
  pacific_40: '#498CED',
  pacific_50: '#1C6FE8',
  pacific_60: '#1553AE',
  pacific_70: '#0E3874',
  pacific_80: '#071C3A',
  primary_300: '#A67df0',
  primary_400: '#7033FF',
  nature_10: '#EAFAF1',
  new_nature_10: '#EAFAF1',
  nature_20: '#ACDFC2',
  nature_30: '#82CFA3',
  nature_40: '#59BF85',
  nature_50: '#0D8060',
  nature_60: '#23834D',
  nature_70: '#185833',
  nature_80: '#0C2C19',
  coral_10: '#D6EFED',
  coral_20: '#ADDEDB',
  coral_30: '#83CEC8',
  coral_40: '#5ABDB6',
  coral_50: '#31ADA4',
  coral_60: '#25827B',
  coral_70: '#195752',
  coral_80: '#0C2B29',
  navy_10: '#CCE8F1',
  navy_20: '#99D2E3',
  navy_30: '#66BBD6',
  navy_40: '#33A5C8',
  navy_50: '#008EBA',
  navy_60: '#006B8B',
  navy_70: '#00475D',
  navy_80: '#00242F',
  ruby_10: '#F4D9F7',
  ruby_20: '#E9B2EF',
  ruby_30: '#DF8CE8',
  ruby_40: '#D465E0',
  ruby_50: '#C93FD8',
  ruby_60: '#972FA2',
  ruby_70: '#65206C',
  ruby_80: '#321036',
  apple_10: '#FFF2F1',
  apple_20: '#F3B5B5',
  apple_30: '#EC9090',
  apple_40: '#E66B6B',
  apple_50: '#DD4B4B',
  apple_60: '#A83535',
  apple_70: '#702323',
  apple_80: '#381212',
  fox_10: '#FDE5CE',
  fox_20: '#FBCC9D',
  fox_30: '#F8B26D',
  fox_40: '#F6993C',
  fox_50: '#F47F0B',
  fox_60: '#B75F08',
  fox_70: '#7A4006',
  fox_80: '#3D2003',
  cheese_5: '#FFF6E0',
  cheese_10: '#FEF6D4',
  cheese_20: '#FEEDA9',
  cheese_30: '#FDE57F',
  cheese_40: '#FDDC54',
  cheese_50: '#FCD329',
  cheese_60: '#BD9E1F',
  cheese_70: '#7E6A15',
  cheese_80: '#3F350A',
  white_100: '#FFFFFF',
  white_70: '#FFFFFF70',
  white_50: '#FFFFFF50',
  white_30: '#FFFFFF30',
  background: 'rgb(243, 249, 252)',
  black_30: '#AFAFAF',
  black: '#000000',
  info_500: '#0089FF',
  independent_10: '#bae1d4',
  independent_20: '#99d2bf',
  independent_30: '#6bbea2',
  independent_40: '#4eb190',
  independent_50: '#229e74',
  independent_60: '#1f906a',
  independent_70: '#187052',
  independent_80: '#135740',
  independent_90: '#0e4231',
  camino_10: '#FFF9EA',
};

export const brandColors = {
  addi: '#427CFE',
};
