import styled from "styled-components";
import { colors } from "../../../../constants/colors";
import { TypesText } from "../@types";
import { fontFamilyPrimaryCss } from "../../../../constants/typography";

export const RootCaption = styled.p<TypesText>`
  font-family: ${fontFamilyPrimaryCss};
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) =>
    props.color === "white"
      ? colors.white_100
      : props.color === "darkblue"
      ? colors.pacific_70
      : props.color === "gray"
      ? colors.stone_60
      : props.color === "blue"
      ? colors.pacific_50
      : props.color === "violet"
      ? colors.eva_50
      : colors.stone_80};
  ${(props) => props.strong && "font-weight: bold;"}
  ${(props) => props.underlined && "text-decoration: underline;"}
  ${(props) => props.cursorMouse && "cursor: pointer;"}
  @media only screen and (max-width: 768px) {
    display: block;
    text-align: center;
  }
`;
