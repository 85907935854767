import React from "react";
import { colors } from "../../constants/colors";
import { fontFamilyPrimary } from "../../constants/typography";
type TypesStyles = {
  [key: string]: React.CSSProperties;
};
const styles: TypesStyles = {
  content: {
    backgroundColor: colors.white,
    position: "relative",
  },
  contentBox: {
    textAlign: "center",
  },
  textTitle1: { color: colors.eva_50, fontSize: 30, fontFamily: fontFamilyPrimary },
  textTitle2: { color: colors.eva_50, fontSize: 30, fontFamily: fontFamilyPrimary },
  descriptionText1: {
    fontSize: 22,
    fontWeight: 500,
    fontStyle: "normal",
    fontFamily: fontFamilyPrimary,
  },
};

export default styles;
