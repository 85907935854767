import { colors } from "../../constants/colors";

export default {
  mainContainer: {
    padding: "0 20px",
    backgroundColor: colors.background,
  },
  mainContainerMaintenance: {
    padding: "0 0px",
    backgroundColor: colors.background,
  },
  header: {
    backgroundColor: colors.eva_80,
    borderBottomRightRadius: "24px",
    borderBottomLeftRadius: "24px",
    paddingTop: 16,
    paddingBottom: 180,
    height: "auto",
  },
};
