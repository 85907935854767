import React, { useState } from "react";
import {
  Form,
  Icon,
  Input,
  Checkbox,
  Button,
  Typography,
  notification,
} from "antd";
import { Link } from "react-router-dom";
import styles from "./signup.style";
import { useOvermind } from "../../overmind/index";

const SignUpPage = (props) => {
  // OVERMIND 
  const {
    actions: { signUp },
    state,
  } = useOvermind();
  const countryCode = state.configurations.COUNTRY_CODE;
  let termsUrl = "";

  switch (countryCode) {
    case "CO":
      termsUrl =
        "https://t3055717.p.clickup-attachments.com/t3055717/559c6e40-9a8a-4256-b822-cbed0a43056e/2024.02.27%20CO%20-%20Acuerdo%20de%20Uso%20de%20Plataforma%20Vendedores.pdf?view=open";
      break;

    case "MX":
      termsUrl =
        "https://elenas-legal-terms-and-conditions.s3.amazonaws.com/AcuerdodeUsodePlataformaVendedoresMX.pdf";
      break;

    default:
      termsUrl =
        "https://t3055717.p.clickup-attachments.com/t3055717/559c6e40-9a8a-4256-b822-cbed0a43056e/2024.02.27%20CO%20-%20Acuerdo%20de%20Uso%20de%20Plataforma%20Vendedores.pdf?view=open";
      break;
  }

  // PROPS
  const { getFieldDecorator } = props.form;

  // STATE
  const [email, setEmail] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [password, setPassword] = useState("");
  const [checkBoxStatus, setCheckBoxStatus] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // INTERNAL VARS
  const { Title } = Typography;

  const formSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const errors = await signUp({ email, cellphone, password });

    if (errors) {
      setLoading(false);
      const message = errors.message
        ? errors.message
        : errors.errors.length
        ? errors.errors[0].message
        : "Algo anda mal";
      notification.error({
        message,
      });
      return;
    }
  };

  return (
    <div>
      <Title style={styles.authTitle} level={3}>
        Crea tu cuenta
      </Title>
      <div style={styles.card}>
        <div style={styles.message} />
        <div style={styles.formContainer}>
          <p style={{ fontSize: 18 }}>
            Tu cuenta en el Marketplace de {process.env.REACT_APP_COMPANY_LONGNAME} te permite manejar tu
            inventario de productos y hacer seguimiento a todas tus ordenes.
          </p>
          <Form onSubmit={formSubmit}>
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "Por favor ingrese su correo electrónico",
                  },
                ],
              })(
                <Input
                  prefix={<Icon type="mail" style={{ color: "#A3A6CC" }} />}
                  type="email"
                  placeholder="Tu correo electrónico"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("cellphone", {
                rules: [
                  {
                    required: true,
                    message: "Por favor ingrese su número celular",
                  },
                ],
              })(
                <Input
                  prefix={<Icon type="phone" style={{ color: "#A3A6CC" }} />}
                  type="phone"
                  placeholder="Tu celular"
                  onChange={(e) => {
                    setCellphone(e.target.value);
                  }}
                  required
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Por favor ingresa una contraseña",
                  },
                ],
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: "#A3A6CC" }} />}
                  type="password"
                  placeholder="Ingresa una contraseña"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  required
                />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator("matchPassword", {
                rules: [
                  {
                    required: true,
                    message: "Por favor repite la contraseña",
                  },
                ],
              })(
                <Input
                  prefix={<Icon type="lock" style={{ color: "#A3A6CC" }} />}
                  type="password"
                  placeholder="Repite la contraseña"
                  onChange={(e) => {
                    if (password !== e.target.value) {
                      setError(true);
                      setErrorMessage("La contraseña no coincide.");
                    } else {
                      setError(false);
                      setErrorMessage("");
                    }
                  }}
                  required
                />
              )}
            </Form.Item>
            <div>{errorMessage}</div>
            <Form.Item style={{ marginBottom: 0, textAlign: "left" }}>
              {getFieldDecorator("terms")(
                <Checkbox
                  checked={checkBoxStatus}
                  onChange={(e) => {
                    setCheckBoxStatus(e.target.checked);
                  }}
                >
                  Leo y acepto los{" "}
                  <a
                    href={termsUrl}
                    /* eslint-disable-next-line react/jsx-no-target-blank */
                    target="_blank"
                    style={{ color: "#1890ff" }}
                  >
                    términos y condiciones
                  </a>
                </Checkbox>
              )}

              <Button
                block
                disabled={!checkBoxStatus || error}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                Regístrate.
              </Button>
              <div style={{ textAlign: "center" }}>
                <span>
                  ¿Ya tienes una cuenta?{" "}
                  <Link to="/auth/signin">Iniciar sesión</Link>{" "}
                </span>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Form.create()(SignUpPage);
